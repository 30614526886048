import { GET_PAYMENT_PARAMS_REQUEST } from 'models';

export const GET_CASH_FEE_LIST_REQUEST: string = 'GET_CASH_FEE_LIST_REQUEST';
export const GET_PAYOUTS_REQUEST: string = 'GET_PAYOUTS_REQUEST';
export const DELETE_CASH_FEE_REQUEST: string = 'DELETE_CASH_FEE_REQUEST';
export const DELETE_TXN_FROM_PAYOUT_REQUEST: string =
  'DELETE_TXN_FROM_PAYOUT_REQUEST';
export const GET_PAYOUTS_DETAIL_REQUEST: string = 'GET_PAYOUTS_DETAIL_REQUEST';

export const removeTransactionFromPayoutRequest = (
  data: any,
  onSuccess?: () => void
) => {
  return {
    type: DELETE_TXN_FROM_PAYOUT_REQUEST,
    payload: { data, onSuccess },
  };
};
export const deleteCashFeeRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_CASH_FEE_REQUEST,
    payload: { id, onSuccess },
  };
};

export const getPayoutsRequest = (params: GET_PAYMENT_PARAMS_REQUEST) => {
  return {
    type: GET_PAYOUTS_REQUEST,
    payload: { params },
  };
};

export const getCashFeeListRequest = (params: GET_PAYMENT_PARAMS_REQUEST) => {
  return {
    type: GET_CASH_FEE_LIST_REQUEST,
    payload: { params },
  };
};

export const GET_TXN_FROM_PAYOUT_LIST_REQUEST: string =
  'GET_TXN_FROM_PAYOUT_LIST_REQUEST';
