import { memo } from 'react';
import { METADATA_TYPE } from './accounts';

export type Order = 'asc' | 'desc';

export interface HeadCell<T = any> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  isSort: boolean;
}

export interface OptionItem<T = string> {
  id: T;
  label?: string;
  [key: string]: any;
}

export interface DataOption<T = string> {
  options: OptionItem<T>[];
  metadata: METADATA_TYPE;
}

export enum FilterOptionType {
  TEXT = 'TEXT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  DATE_PICKER = 'DATE_PICKER',
  SINGLE_CHECKBOX = 'SINGLE_CHECKBOX',
}

export enum StatusType {
  SUCCESS = 'Success',
  FAIL = 'Fail',
  CANCLEED = 'canceled',
  COMPLETED = 'completed',
  UPCOMING = 'upcoming',
  PENDING = 'pending completion',
}

export interface FilterOption {
  name: string;
  key: string;
  type: FilterOptionType;
  placeholder?: string;
}

export type ValueType =
  | OptionItem<any>[]
  | OptionItem<any>
  | string
  | number
  | boolean
  | null
  | undefined;

export interface FilterValue {
  [key: string]: ValueType;
}

export interface FilterCurrentValueItem extends FilterOption {
  value: ValueType;
}

export interface FilterCurrentValue {
  [key: string]: FilterCurrentValueItem;
}

export interface SortItem {
  orderBy: string;
  order: Order;
}

export enum TYPE_POPUP {
  DELETE,
  DETAIL,
  EDIT,
  CREATE,
  REJECT,
  APPROVE_ALL,
  CREATE_OR_EDIT,
  REJECT_ALL,
  ADD,
  ACCEPT,
  DELETE_ALL,
  APPROVE,
  GEN_PENDING,
  CANCEL_ALL,
}

export interface POPUP_ACTION<T extends any = any> {
  type: TYPE_POPUP | string;
  data?: T;
  custom?: any;
}

export interface DataListResponse<T = any> {
  count: number;
  rows: T[];
}

export interface LIST_BODY_RESULT<T = any> {
  records: T[];
  metadata: METADATA_TYPE;
}

export interface LIST_BODY_RESULT_ROWS<T = any> {
  rows: T[];
  metadata: METADATA_TYPE;
}

export enum ECLOSE_POPUP {
  CREATE_OR_EDIT = 'CREATE_OR_EDIT',
  DELETE = 'DELETE',
  REJECT = 'REJECT',
  DETAIL = 'DETAIL',
  ADD = 'ADD',
  ACCEPT = 'ACCEPT',
}

export enum DELETE_DIALOG_TYPE {
  SERVICE_SITTER_REQUEST = 'sitterSR',
  SITTER = 'sitter',
  PARENT = 'parent',
  ADMIN = 'admin',
  SERVICE_REQUEST = 'serviceRequest',
  DETAIL_PARENT = 'detailParent',
  JOB = 'job',
  JUST_ONCE = 'justOnce',
  TRANSACTION = 'transaction',
  PAYMENT = 'payment',
  CASH_FEE = 'cashFee',
  CHAT = 'chat',
  REVIEW = 'review',
  USERS = 'users',
  REGION = 'region',
  REASON = 'reason',
  REPORT = 'report',
  BADGE = 'badge',
  CHAT_KEYWORD_TRACKING = 'chatKeywordTracking',
  DELETE_TXN_FROM_PAYOUT = 'removeTransactionFromPayout',
  DELETE_COUNTRY_FROM_BLACK_LIST = 'removeCountryFromBlackList',
  PROMO_CODES = 'promoCodes',
  NANNY_BOOKING = 'NANNY_BOOKING',
  NANNY_PICTURES = 'NANNY_PICTURES',
  NANNY_TESTIMONIAL = 'NANNY_TESTIMONIAL',
  NANNY_CERTIFICATE = 'NANNY_CERTIFICATE',
  NANNY_CERTIFICATE_ASSOCIATION = 'NANNY_CERTIFICATE_ASSOCIATION',
  NANNY = 'NANNY',
  ACTIVITY_LOG_IN_BOOKING = 'ACTIVITY_LOG_IN_BOOKING',
}

export interface PHONE_TYPE {
  country_code: string;
  phone_number: string;
}

export interface DATA_PAGINATION<T> {
  pageNum: number;
  pageSize: number;
  totalSize: number;
  totalPages: number;
  data: T[];
}

export const yesNoOptions: OptionItem<boolean>[] = [
  { id: true, label: 'Yes' },
  { id: false, label: 'No' },
];

export interface PayloadActionData<T1 extends any = any, T2 extends any = any> {
  data: T1;
  isPopup?: boolean;
  onSuccess?: (data?: T2) => void;
  onFailed?: (err?: any) => void;
  onFinally?: () => void;
}

export interface ResponseType<T> {
  message: string;
  data: T;
}

export const typedMemo: <T>(
  c: T,
  areEqual?: (
    prevProps: Readonly<React.PropsWithChildren<T>>,
    nextProps: Readonly<React.PropsWithChildren<T>>
  ) => boolean
) => T = memo;

export const getKeys = <T extends object = object>(obj: T) =>
  Object.keys(obj) as Array<keyof T>;
