import { all } from 'redux-saga/effects';
import { accountsSaga } from './accounts';
import { authenticationSaga } from './authentication';
import { cashFeeSaga } from './cash-fee';
import { chatSaga } from './chat';
import { flagsSaga } from './flag';
import { jobsSaga } from './jobs/index';
import { nannyBookingsSaga } from './nanny-bookings';
import { paymentSaga } from './payment';
import { reportsSaga } from './reports';
import { requestsSaga } from './requests';
import { reviewsSaga } from './reviews';
import { settingsSaga } from './settings/index';
import { transactionsSaga } from './transactions';

export const rootSaga = function* root() {
  yield all([
    authenticationSaga(),
    accountsSaga(),
    requestsSaga(),
    jobsSaga(),
    transactionsSaga(),
    paymentSaga(),
    chatSaga(),
    reviewsSaga(),
    settingsSaga(),
    reportsSaga(),
    nannyBookingsSaga(),
    flagsSaga(),
    cashFeeSaga(),
  ]);
};
