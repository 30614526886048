import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CASH_TRANSACTION_CODE,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_TXN_PAYOUT_PARAMS_REQUEST,
  PAYMENT_BODY_PARAMS_RESULT,
  PAYMENT_BODY_RESULT,
  PAYMENT_DETAIL_TYPE,
  TXN_PAYOUT_BODY_RESULT,
} from 'models';

export interface cashFeeType {
  cashFee: {
    cashFee_list: PAYMENT_BODY_PARAMS_RESULT;
    cashFee_detail: any | null;
    payouts: PAYMENT_BODY_PARAMS_RESULT;
    payouts_detail: PAYMENT_DETAIL_TYPE | null;
    txn_payout_list: TXN_PAYOUT_BODY_RESULT;
  };
}

export const initialCashFeeState: cashFeeType = {
  cashFee: {
    payouts: {
      params: {
        page: 1,
        limit: 50,
        code: CASH_TRANSACTION_CODE.PAYOUT,
      },
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },
    cashFee_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        page: 1,
        limit: 50,
        code: CASH_TRANSACTION_CODE.CASH_FEE,
      },
    },
    cashFee_detail: null,
    payouts_detail: null,
    txn_payout_list: {
      params: {
        page: 1,
        limit: 10,
      },
      records: [],
      metadata: {
        page: 1,
        limit: 10,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },
  },
};

const cashFeeSlice = createSlice({
  name: 'cashFee',
  initialState: initialCashFeeState,
  reducers: {
    setCashFeeListSuccess(
      state: cashFeeType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.cashFee.cashFee_list = {
        ...state.cashFee.cashFee_list,
        ...action.payload,
      };
    },
    setCashFeeDetailSuccess(
      state: cashFeeType,
      action: PayloadAction<any | null>
    ) {
      state.cashFee.cashFee_detail = action.payload;
    },
    setPayoutsDetailSuccess(
      state: cashFeeType,
      action: PayloadAction<PAYMENT_DETAIL_TYPE | null>
    ) {
      state.cashFee.payouts_detail = action.payload;
    },
    setCashFeeListParams(
      state: cashFeeType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST | null>
    ) {
      state.cashFee.cashFee_list.params = action.payload;
    },
    setPayoutsParams(
      state: cashFeeType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST>
    ) {
      state.cashFee.payouts.params = action.payload;
    },
    setPayoutsSuccess(
      state: cashFeeType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.cashFee.payouts = {
        ...state.cashFee.payouts,
        ...action.payload,
      };
    },
    setTXNPayoutsParams(
      state: cashFeeType,
      action: PayloadAction<GET_TXN_PAYOUT_PARAMS_REQUEST | null>
    ) {
      state.cashFee.txn_payout_list.params = action.payload;
    },
    setTXNPayoutsSuccess(
      state: cashFeeType,
      action: PayloadAction<TXN_PAYOUT_BODY_RESULT>
    ) {
      state.cashFee.txn_payout_list = {
        ...state.cashFee.txn_payout_list,
        ...action.payload,
      };
    },
  },
});

// Actions
export const {
  setCashFeeListSuccess,
  setCashFeeDetailSuccess,
  setCashFeeListParams,
  setPayoutsParams,
  setPayoutsSuccess,
  setPayoutsDetailSuccess,
  setTXNPayoutsParams,
  setTXNPayoutsSuccess,
} = cashFeeSlice.actions;

export default cashFeeSlice.reducer;
