import { combineReducers } from 'redux';
import accountReducer from './accounts';
import authenticationReducer from './authentication';
import cashFeeReducer from './cash-fee';
import globalReducer from './global';
import jobsReducer from './jobs';
import nannyBookingsReducer from './nanny-bookings';
import paymentReducer from './payment';
import promosReducer from './promotions';
import reportsReducer from './reports';
import requestReducer from './requests';
import reviewsReducer from './reviews';
import settingsReducer from './settings';
import transactionsReducer from './transactions';
import userFlagsReducer from './user-flag';

export const rootReducer = combineReducers({
  authenticationReducer,
  globalReducer,
  accountReducer,
  requestReducer,
  jobsReducer,
  transactionsReducer,
  paymentReducer,
  settingsReducer,
  reportsReducer,
  promosReducer,
  nannyBookingsReducer,
  reviewsReducer,
  userFlagsReducer,
  cashFeeReducer,
});
